import { Component, OnInit, ViewChildren, QueryList, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { Label, BaseChartDirective } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ServiceObject } from 'src/app/models/service-object';
import { WebAPIService } from 'src/app/services/web-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.scss']
})
export class UserReportComponent implements OnInit, AfterViewInit {

  @ViewChildren(BaseChartDirective) charts!: QueryList<BaseChartDirective>;
  @ViewChild('myChart') myChartRef!: ElementRef;


  formG1!: FormGroup;
  formG3!: FormGroup;
  formG4!: FormGroup;
  formG5!: FormGroup;

  public optG1: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    tooltips: {
      enabled: true
    },
    legend: {
      display: true
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    plugins: {
      datalabels: {
        display: true,
        color: '#000',
        anchor: 'end',
        align: 'top',
        font: {
          size: 14,
          weight: 'bold'
        },
        formatter: (value: number) => `${value}`
      }
    }
  };

  public labelsG1 = ['Total'];
  public legendG1 = true;
  public dataG1:any;
  
  public labelsG2 = ['Incidentes', 'Requerimientos'];
  public dataG2:any;

  public labelsG3 = ['Equipo Computo', 'Escaner', 'Impresora', 'Otros'];
  public dataG3:any;

  public labelsG5=["CAUSAS"];
  public dataG5: any = [];

  public lineChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        align: 'top' as const,
        
        anchor: 'end' as const,
        color: '#000',
        font: {
          weight: 'bold' as const,
          size: 12
        },
        formatter: (value: number) => `${value}`
      }
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Meses'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Valores'
        }
      }]
    }
  };

  public lineChartLabels = ['1-15', '6-10', '11-15', '16-20', '21-25', '26-31'];
  public lineChartType = 'line';
  public lineChartLegend = true;

  public lineChartData:any;

  constructor(private webAPI: WebAPIService, private formBuilder: FormBuilder, private toast: NotificationService) { }

  ngAfterViewInit(): void {
    Chart.plugins.register(ChartDataLabels);
  }

  ngOnInit(): void {
    this.formG1 = this.formBuilder.group({
      start: ["", Validators.compose([Validators.required])],
      end: ["", Validators.compose([Validators.required])]
    });

    this.formG3 = this.formBuilder.group({
      start: ["", Validators.compose([Validators.required])],
      end: ["", Validators.compose([Validators.required])],
      type: ["", Validators.compose([Validators.required])]
    });

    this.formG4 = this.formBuilder.group({
      start: ["", Validators.compose([Validators.required])],
      end: ["", Validators.compose([Validators.required])]
    });

    this.formG5 = this.formBuilder.group({
      start: ["", Validators.compose([Validators.required])],
      end: ["", Validators.compose([Validators.required])],
      type: ["", Validators.compose([Validators.required])]
    });
  }

  GetG1() {
    if (this.formG1.invalid) {
      this.toast.showWarning("Todos los campos son obligatorios*", "");
    } else if (this.formG1.controls.end.value < this.formG1.controls.start.value) {
      this.toast.showWarning("Revise las fechas", "");
    } else {
      this.toast.showSuccess("Buscando...", "");
      var servObj = new ServiceObject("report/graphic1/" + this.formG1.controls.start.value + "/" + this.formG1.controls.end.value);
      this.webAPI.GetAction(servObj)
        .then(x => {
          console.log(x.data.report[0].INCIDENTE);
          this.dataG1 = [
            { data: [x.data.report[0].INCIDENTE], label: 'Incidentes' },
          { data: [x.data.report[0].REQUERIMIENTO], label: 'Requerimientos' }
          ];
        }).catch(x => { throw x.message; });
    }
  }

  GetG2() {
    if (this.formG1.invalid) {
      this.toast.showWarning("Todos los campos son obligatorios*", "");
    } else if (this.formG1.controls.end.value < this.formG1.controls.start.value) {
      this.toast.showWarning("Revise las fechas", "");
    } else {
      this.toast.showSuccess("Buscando...", "");
      var servObj = new ServiceObject("report/graphic2/" + this.formG1.controls.start.value + "/" + this.formG1.controls.end.value);
      this.webAPI.GetAction(servObj)
        .then(x => {
          this.dataG2 = [
          { data: [x.data.report[0] ? x.data.report[0].INCIDENTE : 0, x.data.report[0] ? x.data.report[0].REQUERIMIENTO : 0], label: 'EQUIPO DE COMPUTO'},
          { data: [x.data.report[1] ? x.data.report[1].INCIDENTE : 0, x.data.report[1] ? x.data.report[1].REQUERIMIENTO : 0], label: 'ESCANER'},
          { data: [x.data.report[2] ? x.data.report[2].INCIDENTE : 0, x.data.report[2] ? x.data.report[2].REQUERIMIENTO : 0], label: 'IMPRESORA'},
          { data: [x.data.report[3] ? x.data.report[3].INCIDENTE : 0, x.data.report[3] ? x.data.report[3].REQUERIMIENTO : 0], label: 'OTROS' }
          ];
        }).catch(x => { throw x.message; });
    }
  }

  GetG3() {
    if (this.formG3.invalid) {
      this.toast.showWarning("Todos los campos son obligatorios*", "");
    } else if (this.formG1.controls.end.value < this.formG1.controls.start.value) {
      this.toast.showWarning("Revise las fechas", "");
    } else {
      this.toast.showSuccess("Buscando...", "");
      var servObj = new ServiceObject("report/graphic3/" + this.formG3.controls.start.value + "/" + this.formG3.controls.end.value + "/" + this.formG3.controls.type.value);
      this.webAPI.GetAction(servObj)
        .then(x => {
          console.log(x.data.report);
          this.dataG3 = [
          { data:[x.data.report[0] ? x.data.report[0].PC : 0, x.data.report[0] ? x.data.report[0].ESCANER : 0, x.data.report[0] ? x.data.report[0].IMPRESORA : 0, x.data.report[0] ? x.data.report[0].OTROS : 0], label: x.data.report[0] ? x.data.report[0].AREA : "N/A"},
          { data:[x.data.report[1] ? x.data.report[1].PC : 0, x.data.report[1] ? x.data.report[1].ESCANER : 0, x.data.report[1] ? x.data.report[1].IMPRESORA : 0, x.data.report[1] ? x.data.report[1].OTROS : 0], label: x.data.report[1] ? x.data.report[1].AREA : "N/A"},
          { data:[x.data.report[2] ? x.data.report[2].PC : 0, x.data.report[2] ? x.data.report[2].ESCANER : 0, x.data.report[2] ? x.data.report[2].IMPRESORA : 0, x.data.report[2] ? x.data.report[2].OTROS : 0], label: x.data.report[2] ? x.data.report[2].AREA : "N/A"},
          { data:[x.data.report[3] ? x.data.report[3].PC : 0, x.data.report[3] ? x.data.report[3].ESCANER : 0, x.data.report[3] ? x.data.report[3].IMPRESORA : 0, x.data.report[3] ? x.data.report[3].OTROS : 0], label: x.data.report[3] ? x.data.report[3].AREA : "N/A"},
          { data:[x.data.report[4] ? x.data.report[4].PC : 0, x.data.report[4] ? x.data.report[4].ESCANER : 0, x.data.report[4] ? x.data.report[4].IMPRESORA : 0, x.data.report[4] ? x.data.report[4].OTROS : 0], label: x.data.report[4] ? x.data.report[4].AREA : "N/A"},
          { data:[x.data.report[5] ? x.data.report[5].PC : 0, x.data.report[5] ? x.data.report[5].ESCANER : 0, x.data.report[5] ? x.data.report[5].IMPRESORA : 0, x.data.report[5] ? x.data.report[5].OTROS : 0], label: x.data.report[5] ? x.data.report[5].AREA : "N/A"},
          { data:[x.data.report[6] ? x.data.report[6].PC : 0, x.data.report[6] ? x.data.report[6].ESCANER : 0, x.data.report[6] ? x.data.report[6].IMPRESORA : 0, x.data.report[6] ? x.data.report[6].OTROS : 0], label: x.data.report[6] ? x.data.report[6].AREA : "N/A"},
          { data:[x.data.report[7] ? x.data.report[7].PC : 0, x.data.report[7] ? x.data.report[7].ESCANER : 0, x.data.report[7] ? x.data.report[7].IMPRESORA : 0, x.data.report[7] ? x.data.report[7].OTROS : 0], label: x.data.report[7] ? x.data.report[7].AREA : "N/A"},
          { data:[x.data.report[8] ? x.data.report[8].PC : 0, x.data.report[8] ? x.data.report[8].ESCANER : 0, x.data.report[8] ? x.data.report[8].IMPRESORA : 0, x.data.report[8] ? x.data.report[8].OTROS : 0], label: x.data.report[8] ? x.data.report[8].AREA : "N/A"},
          ];
        }).catch(x => { throw x.message; });
    }
  }

  GetG4() {
    if (this.formG4.invalid) {
      this.toast.showWarning("Todos los campos son obligatorios*", "");
    } else if (this.formG1.controls.end.value < this.formG1.controls.start.value) {
      this.toast.showWarning("Revise las fechas", "");
    } else {
      this.toast.showSuccess("Buscando...", "");
      var servObj = new ServiceObject("report/graphic4/" + this.formG4.controls.start.value + "/" + this.formG4.controls.end.value);
      this.webAPI.GetAction(servObj)
        .then(x => {
          this.lineChartData = [
            {
              data: [x.data.report[0]["1-5"],x.data.report[0]["6-10"],x.data.report[0]["11-15"], x.data.report[0]["16-20"], x.data.report[0]["21-25"], x.data.report[0]["26-31"]], 
              label: 'Creación',
              borderColor: 'blue',
              backgroundColor: 'rgba(0, 0, 255, 0.3)',
              fill: true,
              lineTension: 0 
            },
            {
              data: [x.data.report[1]["1-5"],x.data.report[1]["6-10"],x.data.report[1]["11-15"], x.data.report[1]["16-20"], x.data.report[1]["21-25"], x.data.report[1]["26-31"]],
              label: 'Solución',
              borderColor: 'red',
              backgroundColor: 'rgba(255, 0, 0, 0.3)',
              fill: true,
              lineTension: 0 
            }
          ];
        }).catch(x => { throw x.message; });
    }
  }

  GetG5() {
    if (this.formG5.invalid) {
      this.toast.showWarning("Todos los campos son obligatorios*", "");
    } else if (this.formG1.controls.end.value < this.formG1.controls.start.value) {
      this.toast.showWarning("Revise las fechas", "");
    } else {
      this.dataG5 = [];
      this.toast.showSuccess("Buscando...", "");
      var servObj = new ServiceObject("report/graphic5/" + this.formG5.controls.start.value + "/" + this.formG5.controls.end.value + "/" + this.formG5.controls.type.value);
      this.webAPI.GetAction(servObj)
        .then(x => {
          x.data.report.forEach((item: any) => {
            this.dataG5.push({
              data: x.data.report.filter((element: any) => element["CAUSA"] === item["CAUSA"]).map((u: { [x: string]: any; }) => u["TOTAL"]),
              label: item["CAUSA"]
            });
          });
        }).catch(x => { throw x.message; });
    }
  }

  downloadChart(index: number) {
    index = (this.charts.toArray().length < index ? this.charts.toArray().length-1 : index);
    console.log(this.charts.toArray());
    const chartInstance = this.charts.toArray()[index]?.chart;

    if (!chartInstance) {
      console.error(`⚠️ Error: La gráfica ${index} no está disponible.`);
      return;
    }

    const canvas = chartInstance.canvas;
    if (!canvas) {
      console.error(`⚠️ Error: No se encontró el canvas para la gráfica ${index}.`);
      return;
    }

    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = `grafica_${index + 1}.png`;
    link.click();
  }  

}
