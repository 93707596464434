<div class="container-fluid">
    <div class="row mt-4 mb-4">
        <div class="card-col col-12">
            <div class="card mb-2 mt-2 d-flex card-main">
                <div class="card-body">        
                    <form [formGroup]="formG1" (ngSubmit)="GetG1()">
                        <div class="row">
                            <div class="col-2 col-sm-2 col-md-1">
                                <button type="button" class="btn btn-lg mb-1" class="btn btn-lg mb-1 btn-outline-success">
                                    <h1><i class="fas fa-chart-bar"></i></h1>
                                </button>
                            </div>
                            <div class="col-10 col-sm-10 col-md-11 justify-content-center align-self-center">
                                <h3>Tipos de Tickets</h3>
                            </div>
                            <div class="mt-4 mb-4 col-6">
                                <label class="form-label">Desde</label>
                                <input type="date" class="form-select" formControlName="start">
                            </div>
                            <div class="mt-4 mb-4 col-6">
                                <label class="form-label">Hasta</label>
                                <input type="date" class="form-select" formControlName="end">
                            </div>
                            <div class="col-12 text-end">
                                <button class="btn btn-success" type="submit">Buscar</button>
                            </div>
                        </div>
                    </form>
                    <div class="row" *ngIf="dataG1">
                        <div class="col-12">
                                <canvas baseChart [datasets]="dataG1" [labels]="labelsG1" [options]="optG1" [legend]="legendG1" chartType="bar"></canvas>
                        </div>
                        <div class="col-12 text-end mt-4">
                            <button (click)="downloadChart(0)" class="btn btn-info">Descargar</button>
                        </div>
                    </div>                
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4 mb-4">
        <div class="card-col col-12">
            <div class="card mb-2 mt-2 d-flex card-main">
                <div class="card-body">        
                    <form [formGroup]="formG1" (ngSubmit)="GetG2()">
                        <div class="row">
                            <div class="col-2 col-sm-2 col-md-1">
                                <button type="button" class="btn btn-lg mb-1" class="btn btn-lg mb-1 btn-outline-success">
                                    <h1><i class="fas fa-chart-bar"></i></h1>
                                </button>
                            </div>
                            <div class="col-10 col-sm-10 col-md-11 justify-content-center align-self-center">
                                <h3>Incidentes vs Requerimientos</h3>
                            </div>
                            <div class="mt-4 mb-4 col-6">
                                <label class="form-label">Desde</label>
                                <input type="date" class="form-select" formControlName="start">
                            </div>
                            <div class="mt-4 mb-4 col-6">
                                <label class="form-label">Hasta</label>
                                <input type="date" class="form-select" formControlName="end">
                            </div>
                            <div class="col-12 text-end">
                                <button class="btn btn-success" type="submit">Buscar</button>
                            </div>
                        </div>
                    </form>
                    <div class="row" *ngIf="dataG2">
                        <div class="col-12">
                                <canvas baseChart [datasets]="dataG2" [labels]="labelsG2" [options]="optG1" [legend]="legendG1" chartType="bar"></canvas>
                        </div>
                        <div class="col-12 text-end mt-4">
                            <button (click)="downloadChart(1)" class="btn btn-info">Descargar</button>
                        </div>
                    </div>                
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4 mb-4">
        <div class="card-col col-12">
            <div class="card mb-2 mt-2 d-flex card-main">
                <div class="card-body">        
                    <form [formGroup]="formG3" (ngSubmit)="GetG3()">
                        <div class="row">
                            <div class="col-2 col-sm-2 col-md-1">
                                <button type="button" class="btn btn-lg mb-1" class="btn btn-lg mb-1 btn-outline-success">
                                    <h1><i class="fas fa-chart-bar"></i></h1>
                                </button>
                            </div>
                            <div class="col-10 col-sm-10 col-md-11 justify-content-center align-self-center">
                                <h3>(Incidentes vs Requerimientos) por Área</h3>
                            </div>
                            <div class="mt-4 mb-4 col-4">
                                <label class="form-label">Desde</label>
                                <input type="date" class="form-select" formControlName="start">
                            </div>
                            <div class="mt-4 mb-4 col-4">
                                <label class="form-label">Hasta</label>
                                <input type="date" class="form-select" formControlName="end">
                            </div>
                            <div class="mt-4 mb-4 col-4">
                                <label class="form-label">Tipo</label>
                                <select class="form-select" formControlName="type">
                                    <option value="0">Incidente</option>
                                    <option value="1">Requerimiento</option>
                                </select>
                            </div>
                            <div class="col-12 text-end">
                                <button class="btn btn-success" type="submit">Buscar</button>
                            </div>
                        </div>
                    </form>
                    <div class="row" *ngIf="dataG3">
                        <div class="col-12">
                                <canvas baseChart [datasets]="dataG3" [labels]="labelsG3" [options]="optG1" [legend]="legendG1" chartType="bar"></canvas>
                        </div>
                        <div class="col-12 text-end mt-4">
                            <button (click)="downloadChart(2)" class="btn btn-info">Descargar</button>
                        </div>
                    </div>                
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4 mb-4">
        <div class="card-col col-12">
            <div class="card mb-2 mt-2 d-flex card-main">
                <div class="card-body">        
                    <form [formGroup]="formG4" (ngSubmit)="GetG4()">
                        <div class="row">
                            <div class="col-2 col-sm-2 col-md-1">
                                <button type="button" class="btn btn-lg mb-1" class="btn btn-lg mb-1 btn-outline-success">
                                    <h1><i class="fas fa-chart-bar"></i></h1>
                                </button>
                            </div>
                            <div class="col-10 col-sm-10 col-md-11 justify-content-center align-self-center">
                                <h3>Creación vs Solución</h3>
                            </div>
                            <div class="mt-4 mb-4 col-6">
                                <label class="form-label">Desde</label>
                                <input type="date" class="form-select" formControlName="start">
                            </div>                            
                            <div class="mt-4 mb-4 col-6">
                                <label class="form-label">Hasta</label>
                                <input type="date" class="form-select" formControlName="end">
                            </div>                            
                            <div class="col-12 text-end">
                                <button class="btn btn-success" type="submit">Buscar</button>
                            </div>
                        </div>
                    </form>
                    <div class="row" *ngIf="lineChartData">
                        <div class="col-12">
                            <canvas 
                            baseChart 
                            [datasets]="lineChartData" 
                            [labels]="lineChartLabels" 
                            [options]="lineChartOptions" 
                            [legend]="lineChartLegend" 
                            chartType="line">
                          </canvas>
                        </div>
                        <div class="col-12 text-end mt-4">
                            <button (click)="downloadChart(3)" class="btn btn-info">Descargar</button>
                        </div>
                    </div>             
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4 mb-4">
        <div class="card-col col-12">
            <div class="card mb-2 mt-2 d-flex card-main">
                <div class="card-body">        
                    <form [formGroup]="formG5" (ngSubmit)="GetG5()">
                        <div class="row">
                            <div class="col-2 col-sm-2 col-md-1">
                                <button type="button" class="btn btn-lg mb-1" class="btn btn-lg mb-1 btn-outline-success">
                                    <h1><i class="fas fa-chart-bar"></i></h1>
                                </button>
                            </div>
                            <div class="col-10 col-sm-10 col-md-11 justify-content-center align-self-center">
                                <h3>Causas X Tipo de Elemento</h3>
                            </div>
                            <div class="mt-4 mb-4 col-4">
                                <label class="form-label">Desde</label>
                                <input type="date" class="form-select" formControlName="start">
                            </div>
                            <div class="mt-4 mb-4 col-4">
                                <label class="form-label">Hasta</label>
                                <input type="date" class="form-select" formControlName="end">
                            </div>
                            <div class="mt-4 mb-4 col-4">
                                <label class="form-label">Tipo</label>
                                <select class="form-select" formControlName="type">
                                    <option value="2">EQUIPO DE COMPUTO</option>
                                    <option value="3">IMPRESORA</option>
                                    <option value="7">ESCANER</option>
                                </select>
                            </div>
                            <div class="col-12 text-end">
                                <button class="btn btn-success" type="submit">Buscar</button>
                            </div>
                        </div>
                    </form>
                    <div class="row" *ngIf="dataG5.length>0">
                        <div class="col-12">
                                <canvas baseChart [datasets]="dataG5" [labels]="labelsG5" [options]="optG1" [legend]="legendG1" chartType="bar"></canvas>
                        </div>
                        <div class="col-12 text-end mt-4">
                            <button (click)="downloadChart(4)" class="btn btn-info">Descargar</button>
                        </div>
                    </div>                
                </div>
            </div>
        </div>
    </div>
</div>

